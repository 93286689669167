footer {
  position: fixed;
  width: 100%;
  background-color: #1976d2;
  padding: 10px;
  text-align: center;
  color: white;
  left: 0;
  bottom: 0;
}

a {
  text-decoration: none;
}